/* Copyright 2020 Karlsruhe Institute of Technology
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License. */

// Use the fonts installed via npm.
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
$lato-font-path: '~lato-font/fonts';

// Custom success color for Bootstrap/Bootswatch.
$success: #009682;

// Prevent loading external fonts via Bootswatch, we import our own instead.
$web-font-path: false;

@import '~bootswatch/dist/flatly/variables';
@import '~bootstrap/scss/bootstrap';
@import '~bootswatch/dist/flatly/bootswatch';

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

@import '~lato-font/scss/public-api';
@include lato-include-font('normal');
@include lato-include-font('bold');

@import '~select2/dist/css/select2';
@import '~@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4';

@import '~katex/dist/katex';
@import '~markdown-it-texmath/css/texmath';
@import '~shepherd.js/dist/css/shepherd';

a {
  background-color: transparent;
  color: $primary;
  text-decoration: none;

  &:focus {
    outline: 0;
  }

  &:hover {
    color: #1a252f;
    text-decoration: none;
  }
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #cccccc;
  margin: 1.5em 10px;
  padding: 1em 10px 0.1em 10px;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

code {
  color: inherit;
}

html {
  height: 100%;
}

input::placeholder {
  font-style: italic;
}

main {
  flex: 1;
  margin-top: 24px;

  @include media-breakpoint-up(md) {
    margin-top: 90px;
  }
}

pre {
  font-family: monospace, monospace;
}

#base-navbar {
  z-index: 1060;

  @include media-breakpoint-up(md) {
    left: 0;
    max-height: 66px;
    position: fixed;
    right: 0;
    top: 0;
  }
}

#base-quick-search-placeholder {
  width: 225px;

  @media (min-width: 1200px) {
    width: 350px;
  }

  input {
    background-color: #1a252f;
    border: none;
    border-left: 1px solid #2c3e50;
    box-shadow: none;
    -webkit-appearance: none;
  }

  span {
    background-color: #1a252f;
    color: white;
    width: 35px;
  }
}

.badge-total {
  @extend .badge;
  @extend .badge-pill;
  @extend .badge-light;
  @extend .badge-mt-minus;
  @extend .border;
  @extend .text-muted;
}

.badge-mt-minus {
  margin-top: -0.2em;
  vertical-align: middle;
}

.badge-mt-plus {
  margin-top: 0.2em;
  vertical-align: middle;
}

.bg-light {
  background-color: #f7f7f7 !important;
}

.border-light {
  border-color: #f7f7f7 !important;
}

.btn-light {
  background-color: #f7f7f7;
  border-color: #ced4da;
  color: black;

  &.disabled, &:disabled {
    background-color: #fcfcfc;
    border-color: #ced4da;
    color: black;
  }

  &.toggle-active {
    background-color: #d4dddf;
  }

  &:hover:not(:disabled) {
    background-color: #dbdbdb;
    border-color: #cfd9db;
    color: black;
  }
}

.btn-link {
  text-decoration: none !important;

  &:hover {
    color: black !important;
  }
}

.card-action .card-body {
  &:active {
    background-color: $list-group-action-active-bg;
  }

  &:focus, &:hover {
    background-color: $list-group-hover-bg;
    text-decoration: none;
    z-index: 1;
  }
}

.color-orcid {
  color: #a6ce39;
}

.crossed {
  text-decoration: line-through;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-breadcrumb {
  background-color: #f7f7f7;
  border: solid #dfdfdf;
  border-width: 1px;
}

.custom-file-label[data-i18n]::after {
  content: attr(data-i18n);
}

.d-flow-root {
  display: flow-root;
}

.dropdown-item {
  @extend .dropdown-item;
  color: $primary;
}

// For elements that should still be clickable inside a stretched link.
.elevated {
  position: relative;
  z-index: 2;
}

.fa-lg {
  font-size: 1.1em;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.form-group.required .form-control-label::after {
  content: '*';
  color: red;
}

.has-error {
  border: solid red;
  border-width: 1px;
}

.input-group .form-control {
  z-index: 3;
}

.input-group-text {
  @extend .input-group-text;
  background-color: #f7f7f7;
}

.invalid-feedback {
  display: block;
}

.max-vh-75 {
  max-height: 75vh !important;
}

.modal {
  z-index: 10010;
}

.modal-backdrop {
  z-index: 10000;
}

.nav {
  &.nav-pills.card-header-pills {
    .nav-item .nav-link.border-active {
      border: 1px solid #d8d8d8;
    }

    @include media-breakpoint-down(sm) {
      display: inline-grid;
      margin: 0;
      min-width: 100%;
    }
  }

  &.nav-tabs.card-header-tabs {
    .nav-item .nav-link {
      cursor: pointer;

      &.active, &.active:hover {
        border-color: #d8d8d8 #d8d8d8 #fff #d8d8d8;
        color: black;
      }

      &:hover {
        border-color: #d8d8d8;
      }
    }

    @include media-breakpoint-down(sm) {
      display: inline-grid;
      margin: 0;
      min-width: 100%;

      .nav-item {
        .nav-link {
          border-color: #d8d8d8;
          border-radius: 0;

          &.active, &.active:hover {
            border-color: #d8d8d8;
          }
        }

        &:first-child .nav-link {
          @include border-top-radius($nav-tabs-border-radius);
        }

        &:last-child .nav-link {
          @include border-bottom-radius($nav-tabs-border-radius);
        }
      }
    }
  }

  &.nav-vertical {
    .nav-link {
      border: 1px solid #dee2e6;
      border-radius: 0;
      border-top: none;
      cursor: pointer;

      &.active {
        border-color: $primary;
      }

      &:first-child {
        @include border-top-radius($nav-tabs-border-radius);
        border-top: 1px solid #dee2e6;
      }

      &:last-child {
        @include border-bottom-radius($nav-tabs-border-radius);
      }
    }
  }
}

.popover {
  max-width: none;
  width: 350px;
}

.sort-handle {
  cursor: pointer;

  &:hover {
    background-color: white !important;
  }
}

// Hacky way to limit the reach of a stretched link.
.stretched-link-container {
  filter: blur(0);
}

.text-default {
  color: #212529 !important;
}

.tooltip-inner {
  max-width: 350px;
  text-align: start;
  white-space: pre-wrap;
}

.vh-75 {
  height: 75vh !important;
}

.ws-normal {
  white-space: normal;
}

.ws-nowrap {
  white-space: nowrap;
}

.ws-pre-wrap {
  white-space: pre-wrap;
}

[tabindex='-1']:focus {
  outline: 0 !important;
}

/* Logo animation */

@keyframes kadi-logo-color {
  0% {
    fill: auto;
  }
  10% {
    fill: #6cc9bc;
  }
  100% {
    fill: auto;
  }
}

.kadi-logo {
  animation: kadi-logo-color;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;

  &:hover {
    fill: #6cc9bc;
    transition: all 0.1s ease;
  }

  &#kadi-logo-one {
    animation-delay: 0s;
  }

  &#kadi-logo-two {
    animation-delay: 0.1s;
  }

  &#kadi-logo-three {
    animation-delay: 0.2s;
  }

  &#kadi-logo-four {
    animation-delay: 0.3s;
  }

  &#kadi-logo-five {
    animation-delay: 0.4s;
  }

  &#kadi-logo-six {
    animation-delay: 0.5s;
  }
}

/* Responsive border classes (https://github.com/twbs/bootstrap/issues/23892) */

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix}-top    { border-top: $border-width solid $border-color; }
    .border#{$infix}-right  { border-right: $border-width solid $border-color; }
    .border#{$infix}-bottom { border-bottom: $border-width solid $border-color; }
    .border#{$infix}-left   { border-left: $border-width solid $border-color; }

    .border#{$infix}-top-0    { border-top: 0 !important; }
    .border#{$infix}-right-0  { border-right: 0 !important; }
    .border#{$infix}-bottom-0 { border-bottom: 0 !important; }
    .border#{$infix}-left-0   { border-left: 0 !important; }

    .border#{$infix}-x {
      border-left: $border-width solid $border-color;
      border-right: $border-width solid $border-color;
    }

    .border#{$infix}-y {
      border-top: $border-width solid $border-color;
      border-bottom: $border-width solid $border-color;
    }
  }
}

/* Responsive sizing classes (https://github.com/twbs/bootstrap/issues/21943) */

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size}     { #{$prop}: $length !important; }
        .max-#{$abbrev}#{$infix}-#{$size} { max-#{$prop}: $length !important; }
        .min-#{$abbrev}#{$infix}-#{$size} { min-#{$prop}: $length !important; }
      }
    }
  }
}

/* Classes for commonly used max sizes for images */

@each $size in 75, 100, 115 {
  .img-max-#{$size} {
    max-height: #{$size}px;
    max-width: #{$size}px;
  }
}

/* CodeMirror */

.cm-editor {
  height: 100%;

  &.cm-focused {
    outline: 0 !important;
  }
}

.cm-gutters {
  background-color: #f7f7f7 !important;
}

.cm-scroller {
  overflow: auto;
}

/* KaTeX */

.katex {
  > .katex-html {
    white-space: normal;
  }

  .base {
    margin-bottom: 2px;
    margin-top: 2px;
  }
}

/* Plotly */

.plotly-notifier {
  display: none !important;
}

/* Select2 */

:not(.input-group) > .select2.select2-container {
  width: 100% !important;
}

.input-group {
  .select2.select2-container:nth-child(3) .select2-selection {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }

  .select2.select2-container:not(:last-child) .select2-selection {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
  }
}

.select2.select2-container {
  z-index: 3;

  &.select2-container--disabled .select2-selection {
    background-color: $input-disabled-bg;
    cursor: default;
    pointer-events: none;
  }

  &.select2-container--focus {
    .select2-selection {
      background-color: $custom-select-bg;
      border-color: #597ea2;
      box-shadow: 0 0 0 0.2rem rgba(44, 62, 80, 0.25);
      color: #7b8a8b;
      outline: 0;
    }

    &.select2-container--above .select2-selection {
      border-bottom: 1px solid $input-focus-border-color;
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }

  .select2-selection {
    background-color: $custom-select-bg;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    width: 100%;

    .has-error + & {
      border: 1px solid red;
      border-radius: 0.25rem;
      width: 100%;
    }
  }

  .select2-selection--single {
    background: $custom-select-background;
    background-color: $custom-select-bg;

    .select2-selection__arrow {
      display: none;
    }

    .select2-selection__clear {
      background-color: $custom-select-bg;
      border-radius: 0;
      color: #7b8a8b;
      font-size: 17pt;
      font-weight: bold;
      margin-right: 0.4em;
      margin-top: auto;
      line-height: inherit;

      &:hover {
        color: black;
      }
    }

    .select2-selection__placeholder {
      color: $input-color;
      font-style: italic;
    }

    .select2-selection__rendered {
      color: $input-color;
    }
  }

  .select2-selection--multiple {
    .select2-selection__choice {
      margin-left: 5px;
      margin-top: 7px;
    }

    .select2-selection__rendered {
      color: $input-color;
      margin-left: -5px;

      .select2-selection__choice__remove {
        color: #7b8a8b;
        font-size: 17px;
        line-height: 23px;
        margin-right: 0.4em;

        &:hover {
          color: black;
        }
      }
    }
  }

  .select2-selection__rendered .select2-search--inline {
    &:first-child .select2-search__field {
      padding-left: 12px;
    }

    .select2-search__field {
      color: #7b8a8b;
      margin-top: 7px;

      &::placeholder {
        color: #7b8a8b;
        opacity: 1;
      }
    }
  }

  .select2-single-sm.select2-selection {
    &.select2-selection--single, .select2-selection__rendered  {
      @include font-size($custom-select-font-size-sm);
      height: $custom-select-height-sm !important;
      line-height: $custom-select-height-sm !important;
    }

    .select2-selection__placeholder {
      line-height: 1em !important;
    }
  }
}

.select2-container .select2-results__options  {
  word-wrap: break-word;

  .select2-results__option--highlighted {
    background-color: $primary !important;
  }
}

.select2-hidden-accessible {
  margin-top: 35px !important;
}

/* Shepherd */

.shepherd-button {
  @extend .btn-sm;

  background: $primary !important;
  color: #fff !important;

  &:hover {
    background: darken($primary, 7.5%) !important;
    color: #fff !important;
  }
}

.shepherd-element {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.6);
  transition: none !important;
}

.shepherd-footer {
  padding-top: 1em;
}

.shepherd-header {
  padding: 0.5em 1em 0.5em 1em !important;
}

.shepherd-modal-overlay-container {
  transition: none !important;
}

.shepherd-target.shepherd-target-click-disabled.shepherd-enabled .stretched-link::after {
  pointer-events: none;
}

.shepherd-title {
  font-weight: bold;
}

/* Vue */

[v-cloak] {
  display: none;
}

:not([v-cloak]) + [data-placeholder] {
  display: none !important;
}
